/* global HiPay */

import defaultPaymentConfig from './default-payment-config.js';
import defaultStylesConfig from './default-styles-config.js';

const defaultState = {
  currency: 'EUR',
  iframe: false,
  config: {
    sdk_backend: {
      sdk: 'PHP',
      environment: 'stage',
      username: '',
      password: '',
      secure_vault_url: '',
      gateway_url: ''
    },
    sdk_frontend: {
      environment: 'production'
    },
    client: {
      username: '',
      password: '',
      environment: 'stage',
      lang: 'fr-FR'
    },
    payment_mode: {
      mode: 'HP',
      method: 'card'
    },
    payment: {
      ...defaultPaymentConfig
    },
    styles: {
      ...defaultStylesConfig
    }
  },
  draftConfig: null,
  isPaypalV2Available: null
};

const initSdk = (client) => {
  let init_client = { ...client };

  // If env != production && If public credentials not filled, use default credentials
  if (
    init_client.environment !== 'production' &&
    (!init_client.username || !init_client.password)
  ) {
    init_client.username = process.env.REACT_APP_TEST_ACCOUNT_PUBLIC_USERNAME;
    init_client.password = process.env.REACT_APP_TEST_ACCOUNT_PUBLIC_PASSWORD;
  }

  init_client.lang = init_client.lang.substring(0, 2);

  window.hipay = HiPay(init_client);
};

const getInitialState = () => {
  // Get default state
  let initialState = { ...defaultState };
  // Init draft config
  initialState.draftConfig = { ...initialState.config };
  // Return initial state
  return initialState;
};

// Get initial state
const initialState = getInitialState();
// Init SDK instance
initSdk(initialState.config.client);

const sdkConfigReducer = (state = initialState, action) => {
  let newState;

  switch (action.type) {
    case 'UPDATE_LANGUAGE':
      const currencies = {
        en: 'GBP',
        'en-US': 'USD',
        'pt-BR': 'BRL',
        'es-MX': 'MXN'
      };
      const currency = currencies.hasOwnProperty(action.lang)
        ? currencies[action.lang]
        : 'EUR';
      const locale = action.lang.replace('-', '_');

      // Update lang in config and draft config
      // Update currency
      newState = {
        ...state,
        currency: currency,
        config: {
          ...state.config,
          client: { ...state.config.client, lang: action.lang },
          payment: {
            request: {
              ...state.draftConfig.payment.request,
              currencyCode: currency,
              currency,
              locale
            },
            ...state.draftConfig.payment,
            // TO DO: remove it after fix HF
            paymentRequestButton: {
              ...state.draftConfig.payment.paymentRequestButton,
              request: {
                ...state.draftConfig.payment.paymentRequestButton.request,
                currencyCode: currency
              }
            }
          }
        },
        draftConfig: {
          ...state.draftConfig,
          client: {
            ...state.draftConfig.client,
            lang: action.lang
          },
          payment: {
            request: {
              ...state.draftConfig.payment.request,
              currencyCode: currency,
              currency,
              locale
            },
            ...state.draftConfig.payment,
            // TO DO: remove it after fix HF
            paymentRequestButton: {
              ...state.draftConfig.payment.paymentRequestButton,
              request: {
                ...state.draftConfig.payment.paymentRequestButton.request,
                currencyCode: currency
              }
            }
          }
        }
      };
      // Update SDK instance
      initSdk(newState.config.client);
      // Return state
      return newState;

    case 'SAVE_PAYMENT_MODE':
      return {
        ...state,
        config: {
          ...state.config,
          payment_mode: {
            ...state.draftConfig.payment_mode
          }
        }
      };

    case 'SAVE_SDK_SETTINGS':
      // Copy tmpConfig in appConfig
      newState = {
        ...state,
        config: {
          ...state.config,
          sdk_frontend: {
            ...state.draftConfig.sdk_frontend
          },
          sdk_backend: {
            ...state.draftConfig.sdk_backend
          },
          client: {
            ...state.draftConfig.client
          },
          payment: {
            ...state.draftConfig.payment
          }
        }
      };
      initSdk(newState.config.client);
      // Return state
      return newState;

    case 'SET_SDK_LOADING':
      if (action.isLoading === false && action.error === false) {
        initSdk(state.config.client);
      }

      return state;

    case 'SAVE_PAYMENT_SETTINGS':
      // Copy tmpConfig in appConfig
      newState = {
        ...state,
        config: {
          ...state.config,
          payment_mode: {
            mode: state.config.payment_mode.mode,
            method: state.draftConfig.payment_mode.method
          },
          payment: {
            ...state.draftConfig.payment
          }
        }
      };

      // Return state
      return newState;

    case 'SAVE_STYLES_SETTINGS':
      // Copy tmpConfig in appConfig
      newState = {
        ...state,
        config: {
          ...state.config,
          styles: {
            ...state.draftConfig.styles
          },
          payment: {
            ...state.draftConfig.payment,
            paymentRequestButton: {
              ...state.draftConfig.payment.paymentRequestButton,
              applePayStyle: {
                ...state.draftConfig.payment.paymentRequestButton.applePayStyle
              }
            },
            paypal: {
              ...state.draftConfig.payment.paypal,
              paypalButtonStyle: {
                ...state.draftConfig.payment.paypal.paypalButtonStyle
              }
            }
          }
        }
      };

      // Return state
      return newState;

    case 'RESET_SETTINGS':
      // Copy tmpConfig in appConfig
      newState = {
        ...state,
        draftConfig: { ...state.config }
      };

      // Return state
      return newState;

    case 'UPDATE_PAYMENT_MODE':
      // Update url state
      newState = {
        ...state,
        draftConfig: {
          ...state.draftConfig,
          payment_mode: {
            mode: action.mode,
            method: action.method
              ? action.method
              : state.draftConfig.payment_mode.method
          }
        }
      };

      // Return state
      return newState;

    case 'UPDATE_FRONTEND_SDK_CONFIG':
      newState = {
        ...state,
        draftConfig: {
          ...state.draftConfig,
          sdk_frontend: {
            ...state.draftConfig.sdk_frontend,
            [action.name]: action.value
          }
        }
      };

      // Return state
      return newState;

    case 'UPDATE_BACKEND_SDK_CONFIG':
      newState = {
        ...state,
        draftConfig: {
          ...state.draftConfig,
          sdk_backend: {
            ...state.draftConfig.sdk_backend,
            [action.name]: action.value
          }
        }
      };

      // Return state
      return newState;

    case 'UPDATE_BACKEND_ENV':
      newState = {
        ...state,
        draftConfig: {
          ...state.draftConfig,
          sdk_backend: {
            ...state.draftConfig.sdk_backend,
            [action.name]: action.value
          }
        }
      };

      // If change sdk backend envrionment, change amount for paypal v2 and ApplePay
      if (action.name === 'environment') {
        newState.draftConfig = {
          ...newState.draftConfig,
          payment: {
            ...newState.draftConfig.payment,
            request: {
              ...newState.draftConfig.payment.request,
              amount: action.value === 'production' ? '1.0' : '100',
              total: {
                ...newState.draftConfig.payment.request.total,
                amount: action.value === 'production' ? '1.0' : '100'
              }
            },
            // TO DO: remove it after fix HF
            paymentRequestButton: {
              ...newState.draftConfig.payment.paymentRequestButton,
              request: {
                ...newState.draftConfig.payment.paymentRequestButton.request,
                total: {
                  ...newState.draftConfig.payment.paymentRequestButton.request
                    .total,
                  amount: action.value === 'production' ? '1.0' : '100'
                }
              }
            }
          }
        };
      }

      // If change env, update client too
      if (action.name === 'environment') {
        newState.draftConfig.client = {
          ...newState.draftConfig.client,
          environment: action.value
        };
      }

      // If env = custom, update client custom urls
      if (newState.draftConfig.sdk_backend.environment === 'custom') {
        newState.draftConfig.client = {
          ...newState.draftConfig.client,
          custom_urls: {
            secure_vault: newState.draftConfig.sdk_backend.secure_vault_url,
            gateway: newState.draftConfig.sdk_backend.gateway_url
          }
        };

        // If url empty, remove it from object
        if (!newState.draftConfig.sdk_backend.secure_vault_url) {
          delete newState.draftConfig.client.custom_urls.secure_vault;
        }
        // If url empty, remove it from object
        if (!newState.draftConfig.sdk_backend.gateway_url) {
          delete newState.draftConfig.client.custom_urls.gateway;
        }
      }
      // Else remove custom_urls option
      else {
        delete newState.draftConfig.client.custom_urls;
      }

      // Return state
      return newState;

    case 'UPDATE_CLIENT_CONFIG':
      // Update client state
      newState = {
        ...state,
        draftConfig: {
          ...state.draftConfig,
          client: {
            ...state.draftConfig.client,
            [action.name]: action.value
          }
        }
      };

      if (action.name === 'environment' && action.value === 'custom') {
        newState.draftConfig.client.custom_urls = {};
      }
      // Return state
      return newState;

    case 'UPDATE_PAYMENT_PRODUCT_CONFIG':
      if (action.field === 'option') {
        return {
          ...state,
          draftConfig: {
            ...state.draftConfig,
            payment: {
              ...state.draftConfig.payment,
              [action.payment_product]: {
                ...state.draftConfig.payment[action.payment_product],
                [action.name]: action.value
              }
            }
          }
        };
      } else if (action.field === 'one_click') {
        return {
          ...state,
          draftConfig: {
            ...state.draftConfig,
            payment: {
              ...state.draftConfig.payment,
              [action.payment_product]: {
                ...state.draftConfig.payment[action.payment_product],
                one_click: {
                  ...state.draftConfig.payment[action.payment_product]
                    .one_click,
                  [action.name]: action.value
                }
              }
            }
          }
        };
      } else {
        if (typeof action.value !== 'boolean' && !action.value) {
          let { [action.name]: omit, ...rest } =
            state.draftConfig.payment[action.payment_product].fields[
              action.field
            ];
          return {
            ...state,
            draftConfig: {
              ...state.draftConfig,
              payment: {
                ...state.draftConfig.payment,
                [action.payment_product]: {
                  ...state.draftConfig.payment[action.payment_product],
                  fields: {
                    ...state.draftConfig.payment[action.payment_product].fields,
                    [action.field]: {
                      ...rest
                    }
                  }
                }
              }
            }
          };
        } else {
          return {
            ...state,
            draftConfig: {
              ...state.draftConfig,
              payment: {
                ...state.draftConfig.payment,
                [action.payment_product]: {
                  ...state.draftConfig.payment[action.payment_product],
                  fields: {
                    ...state.draftConfig.payment[action.payment_product].fields,
                    [action.field]: {
                      ...state.draftConfig.payment[action.payment_product]
                        .fields[action.field],
                      [action.name]: action.value
                    }
                  }
                }
              }
            }
          };
        }
      }

    case 'UPDATE_DISPLAY_CANCEL_BUTTON':
      return {
        ...state,
        draftConfig: {
          ...state.draftConfig,
          payment: {
            ...state.draftConfig.payment,
            [action.name]: action.value
          }
        }
      };

    case 'UPDATE_STYLES_CONFIG':
      if (!action.value) {
        let { [action.name]: omit, ...rest } =
          state.draftConfig.styles[action.category];
        return {
          ...state,
          draftConfig: {
            ...state.draftConfig,
            styles: {
              ...state.draftConfig.styles,
              [action.category]: {
                ...rest
              }
            }
          }
        };
      } else {
        return {
          ...state,
          draftConfig: {
            ...state.draftConfig,
            styles: {
              ...state.draftConfig.styles,
              [action.category]: {
                ...state.draftConfig.styles[action.category],
                [action.name]: action.value
              }
            }
          }
        };
      }

    case 'UPDATE_APPLE_PAY_STYLES_CONFIG':
      return {
        ...state,
        draftConfig: {
          ...state.draftConfig,
          payment: {
            ...state.draftConfig.payment,
            paymentRequestButton: {
              ...state.draftConfig.payment.paymentRequestButton,
              applePayStyle: {
                ...state.draftConfig.payment.paymentRequestButton.applePayStyle,
                [action.name]: action.value
              }
            }
          }
        }
      };

    case 'UPDATE_PAYPAL_V2_STYLES_CONFIG':
      return {
        ...state,
        draftConfig: {
          ...state.draftConfig,
          payment: {
            ...state.draftConfig.payment,
            paypal: {
              ...state.draftConfig.payment.paypal,
              paypalButtonStyle: {
                ...state.draftConfig.payment.paypal.paypalButtonStyle,
                [action.name]: action.value
              }
            }
          }
        }
      };

    case 'UPDATE_HPAGE_THEME_CODE':
    case 'UPDATE_HPAGE_IFRAME_CONFIG':
      return {
        ...state,
        [action.name]: action.value
      };

    case 'UPDATE_HPAGE_CURRENT_PAYMENT_DATA':
      return {
        ...state,
        draftConfig: {
          ...state.draftConfig,
          [action.name]: action.value
        }
      };

    case 'SET_IS_PAYPAL_V2_AVAILABLE':
      return {
        ...state,
        isPaypalV2Available: action.value
      };
    case 'UPDATE_ONE_CLICK_SAVED_CARDS':
      return {
        ...state,
        config: {
          ...state.config,
          payment: {
            ...state.config.payment,
            card: {
              ...state.config.payment['card'],
              one_click: {
                ...state.config.payment['card'].one_click,
                cards: action.value
              }
            }
          }
        },
        draftConfig: {
          ...state.draftConfig,
          payment: {
            ...state.draftConfig.payment,
            card: {
              ...state.draftConfig.payment['card'],
              one_click: {
                ...state.draftConfig.payment['card'].one_click,
                cards: action.value
              }
            }
          }
        }
      };
    case 'REMOVE_ONE_CLICK_SAVED_CARDS_LIMIT':
      return {
        ...state,
        draftConfig: {
          ...state.draftConfig,
          payment: {
            ...state.draftConfig.payment,
            card: {
              ...state.draftConfig.payment['card'],
              one_click: {
                enabled: state.draftConfig.payment['card'].one_click['enabled'],
                cards: state.draftConfig.payment['card'].one_click['cards']
              }
            }
          }
        }
      };
    default:
      return state;
  }
};

export default sdkConfigReducer;
