import './HostedPaymentRequestButton.scss';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { useHostedInstance } from '../../contexts/HostedInstanceContext';

function HostedPaymentRequestButton(props) {
  const { initHostedInstance, paymentRequestButtonInstance } =
    useHostedInstance();

  useEffect(
    () => {
      // Init instance on mount
      initHostedInstance('paymentRequestButton', props.config);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Hide Payment Request Button if instance empty
  useEffect(() => {
    document.getElementById('payment-request-button-container').style.display =
      paymentRequestButtonInstance ? 'block' : 'none';
  }, [paymentRequestButtonInstance]);

  return (
    <div
      className="payment-request-button-container"
      id="payment-request-button-container"
    />
  );
}

const mapStateToProps = (state) => {
  return {
    config: {
      request: state.sdkConfigReducer.config.payment.request,
      ...state.sdkConfigReducer.config.payment.paymentRequestButton
    }
  };
};

export default connect(mapStateToProps, null)(HostedPaymentRequestButton);
