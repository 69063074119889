import './PaymentState.scss';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';
import queryString from 'query-string';

import { HiButton } from '@hipay/design-system/components';
import JSONPretty from 'react-json-pretty';
import SvgIcon from '@mui/material/SvgIcon';
import { Launch } from '@mui/icons-material';

import { useHostedInstance } from '../../contexts/HostedInstanceContext';
import {
  getPaymentModeDraftConfig,
  getBackendSdkConfig,
  getCurrency
} from '../../selectors/configSelectors';

import { updateOneClickSavedCards } from '../../actions/sdkConfigActions';

import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
// To cancel order request
const CancelToken = axios.CancelToken;
let source;

const console_url_production = 'https://console.hipay.com/transactions/';
const console_url_stage = 'https://stage-console.hipay.com/transactions/';

function PaymentState(props) {
  const {
    hostedInstance,
    destroyHostedInstance,
    paymentRequestButtonInstance,
    applePayHipayTokenInstance,
    paypalHipayTokenInstance,
    setApplePayHipayTokenInstance,
    setPaypalHipayTokenInstance
  } = useHostedInstance();

  const [tokenizationData, setTokenizationDatas] = useState(null);
  const [tokenizationErrors, setTokenizationErrors] = useState(null);

  const [orderData, setOrderData] = useState(null);
  const [orderErrors, setOrderErrors] = useState(null);

  const [isForwarded, setForwarded] = useState(false);
  const [hasReference, setHasReference] = useState(false);

  const location = useLocation();
  const urlParams = { ...queryString.parse(location.search) };
  let navigate = useNavigate();

  // On mount
  useEffect(
    () => {
      // Redirect if access direct from url
      // Recreate Object hasOwnProperty because query string remove it
      if (!hostedInstance && !urlParams.hasOwnProperty('reference')) {
        handleRestart();
      }

      // Create source for Order cancel
      source = CancelToken.source();

      // If reference, display transaction status
      if (urlParams.hasOwnProperty('reference')) {
        setForwarded(true);
        if (urlParams.state === 'completed' || urlParams.state === 'pending') {
          setOrderData(urlParams);
        } else {
          setOrderErrors(urlParams);
        }
      }
      // Else if hosted instance, call getPaymentData
      else if (hostedInstance) {
        // If Apple Pay then add 'cb' in tokenizationDatas
        if (applePayHipayTokenInstance) {
          setTokenizationDatas(applePayHipayTokenInstance);
        } else if (paypalHipayTokenInstance) {
          setTokenizationDatas(paypalHipayTokenInstance);
        } else {
          hostedInstance
            .getPaymentData()
            .then((response) => {
              if (response.one_click && response.card_id) {
                updateSavedCards(response);
              }
              setTokenizationDatas(response);
            })
            .catch((errors) => {
              setTokenizationErrors(errors);
            });
        }
      }

      return () => {
        // Cancel order
        source.cancel();
        source = null;

        // Destroy hosted instance
        destroyHostedInstance();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateSavedCards = (response) => {
    let cardsAlreadySaved =
      JSON.parse(localStorage.getItem('saved_cards')) || [];
    let newCard = {
      token: response.token,
      brand: response.payment_product,
      pan: response.pan,
      card_expiry_month: response.card_expiry_month,
      card_expiry_year: response.card_expiry_year,
      card_holder: response.card_holder
    };

    const existingCardIndex = cardsAlreadySaved.findIndex(
      (card) => card.pan === newCard.pan
    );
    if (existingCardIndex !== -1) {
      cardsAlreadySaved.splice(existingCardIndex, 1);
    }

    cardsAlreadySaved.push(newCard);

    localStorage.setItem('saved_cards', JSON.stringify(cardsAlreadySaved));
    props.updateOneClickSavedCards(cardsAlreadySaved);
  };

  // When receive tokenization data, call api order
  useEffect(
    () => {
      if (tokenizationData) {
        processOrder(tokenizationData)
          .then((response) => {
            if (
              response.data.hasOwnProperty('forwardUrl') &&
              !['multibanco', 'sisal'].includes(response.data.paymentProduct) &&
              !paypalHipayTokenInstance
            ) {
              window.location = response.data.forwardUrl;
            } else {
              setOrderData(response.data);
              // Apple Pay Success
              if (applePayHipayTokenInstance) {
                setApplePayHipayTokenInstance(null);
                if (paymentRequestButtonInstance) {
                  paymentRequestButtonInstance.completePaymentWithSuccess();
                } else {
                  hostedInstance.completePaymentWithSuccess();
                }
              }

              if (response.data.referenceToPay) {
                if (response.data.paymentProduct === 'multibanco') {
                  setHasReference(true);
                  window.hipay.createReference('multibanco', {
                    selector: 'referenceToPay',
                    reference: JSON.parse(response.data.referenceToPay)
                      .reference,
                    entity: JSON.parse(response.data.referenceToPay).entity,
                    amount: JSON.parse(response.data.referenceToPay).amount,
                    expirationDate: JSON.parse(response.data.referenceToPay)
                      .expirationDate
                  });
                } else if (response.data.paymentProduct === 'sisal') {
                  setHasReference(true);
                  window.hipay.createReference('sisal', {
                    selector: 'referenceToPay',
                    reference: JSON.parse(response.data.referenceToPay)
                      .reference,
                    barCode: JSON.parse(response.data.referenceToPay).barCode
                  });
                }
              }
            }
          })
          .catch((err) => {
            // Display error if not canceled request
            if (!axios.isCancel(err)) {
              // handle error
              setOrderErrors(err.response?.data);
            }

            // Apple Pay Failure
            if (applePayHipayTokenInstance) {
              setApplePayHipayTokenInstance(null);
              if (paymentRequestButtonInstance) {
                paymentRequestButtonInstance.completePaymentWithFailure();
              } else {
                hostedInstance.completePaymentWithFailure();
              }
            }
          })
          .finally(() => {
            if (paypalHipayTokenInstance) {
              setPaypalHipayTokenInstance(null);
            }
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokenizationData]
  );

  const processOrder = (paymentData) => {
    return axios.post(
      process.env.REACT_APP_BACKEND_URL,
      {
        referrer: {
          checkoutUrl: `${process.env.REACT_APP_FRONTEND_URL}/payment`
        },
        client: {
          ...props.client
        },
        order: {
          currency: props.currency,
          amount: props.client.environment === 'production' ? 1.0 : 100,
          shipping: props.client.environment === 'production' ? 0.3 : 30
        },
        payment: {
          ...paymentData
        },
        isApplePay: !!applePayHipayTokenInstance
      },
      {
        cancelToken: source.token
      }
    );
  };

  // When restart clicked (or Home), reset hostedInstance & go to home page
  const handleRestart = () => {
    // Redirect to home
    navigate('/');
  };

  // When button clicked, go to Console transaction notice
  const handleGoToConsole = () => {
    const consoleUrl =
      props.client.environment === 'production'
        ? console_url_production
        : console_url_stage;
    const transactionId = orderData.reference;
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(`${consoleUrl + transactionId}`, '_blank');
  };

  return (
    <div className="page-content">
      <div className="PaymentState">
        {!isForwarded && (
          <>
            <div className="step-title">
              <div className="title">
                <div />
                <div>
                  <FormattedMessage id={'payment-step-1'} />
                </div>
                <div>
                  {tokenizationData && (
                    <SvgIcon component="div">
                      <img
                        src={`${process.env.PUBLIC_URL}/static/images/tick.svg`}
                        alt="ok"
                        height="30px"
                        width="30px"
                      />
                    </SvgIcon>
                  )}
                  {tokenizationErrors && (
                    <SvgIcon component="div">
                      <img
                        src={`${process.env.PUBLIC_URL}/static/images/error.svg`}
                        alt="error"
                        height="40px"
                        width="40px"
                      />
                    </SvgIcon>
                  )}
                  {!tokenizationData && !tokenizationErrors && (
                    <LoadingSpinner />
                  )}
                </div>
              </div>
              <div className="step-subtitle">
                <FormattedMessage id={'payment-step-1-help-1'} />
                <br />
                <FormattedMessage id={'payment-step-1-help-2'} />
              </div>
            </div>

            {tokenizationData && (
              <div className="step-result">
                <div className="step-result-code">
                  <JSONPretty data={tokenizationData} />
                </div>
              </div>
            )}

            {tokenizationErrors && <div>error</div>}
            {(tokenizationData || tokenizationErrors) && (
              <div className="divider" />
            )}
          </>
        )}

        {(isForwarded || tokenizationData || tokenizationErrors) && (
          <>
            <div className="step-title">
              <div className="title">
                <div />
                <div>
                  <FormattedMessage id={'payment-step-2'} />
                </div>
                <div>
                  {orderData && (
                    <SvgIcon component="div">
                      <img
                        src={`${process.env.PUBLIC_URL}/static/images/tick.svg`}
                        alt="ok"
                        height="30px"
                        width="30px"
                      />
                    </SvgIcon>
                  )}
                  {orderErrors && (
                    <SvgIcon component="div">
                      <img
                        src={`${process.env.PUBLIC_URL}/static/images/error.svg`}
                        alt="error"
                        height="30px"
                        width="30px"
                      />
                    </SvgIcon>
                  )}
                  {!orderData && !orderErrors && <LoadingSpinner />}
                </div>
              </div>
              <div className="step-subtitle">
                <FormattedMessage id={'payment-step-2-help'} />
              </div>
            </div>

            {(orderData || orderErrors) && (
              <>
                {orderData && (
                  <div className="step-result">
                    <div className="step-result-text">
                      <FormattedMessage id={'payment-step-2-accepted'} />
                      <span className="transaction-number">
                        {orderData.reference}
                      </span>
                      .
                    </div>
                    <div className="step-result-code">
                      <pre>
                        {orderData.hasOwnProperty('transaction') ? (
                          <JSONPretty data={orderData.transaction} />
                        ) : (
                          <JSONPretty data={orderData} />
                        )}
                      </pre>
                    </div>
                  </div>
                )}

                {orderErrors && (
                  <div className="step-result step-errors">
                    <div className="step-result-code step-result-code-error">
                      <JSONPretty data={orderErrors} />
                    </div>
                  </div>
                )}
              </>
            )}
            {hasReference && (
              <div className="ref-text">
                <FormattedMessage id={'reference-to-pay'} />
              </div>
            )}
            <div id="referenceToPay"></div>
          </>
        )}

        <div className="actions">
          <HiButton
            className="action-button"
            variant={
              (isForwarded || tokenizationData || tokenizationErrors) &&
              (orderData || orderErrors)
                ? 'outlined'
                : 'text'
            }
            onClick={handleRestart}
          >
            <FormattedMessage id={'restart'} />
          </HiButton>
          {orderData && (
            <HiButton
              className="action-button"
              color="primary"
              variant="contained"
              onClick={handleGoToConsole}
              endIcon={<Launch />}
            >
              <FormattedMessage id={'view-in-console'} />
            </HiButton>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    client: getBackendSdkConfig(state),
    currency: getCurrency(state),
    payment_mode: getPaymentModeDraftConfig(state)
  };
};

export default connect(mapStateToProps, {
  updateOneClickSavedCards
})(PaymentState);
